<template>
  <div>

    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(quotation)" class="form" autocomplete="off">
        <div class="text-center">
          <div class="pill-radio mb-4">
            <div class="pill-radio-item" @click="changeToNaturalType">
              <input :checked="dataForm.personType == userTypes.NATURAL" type="radio" name="tipo_pessoa" id="tipo-pessoa-fisica"
                value="pessoa-fisica">
                <label for="tipo-pessoa-fisica" style="margin-bottom: 0px !important;">
                  Pessoa física
                </label>
              </div>
            <div class="pill-radio-item active" @click="changeToLegalType">
              <input type="radio" name="tipo_pessoa" id="tipo-pessoa-juridica"
                value="pessoa-juridica" :checked="dataForm.personType == userTypes.LEGAL">
                <label for="tipo-pessoa-juridica" style="margin-bottom: 0px !important;">
                  Pessoa jurídica
                </label>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6 mb-3">
            <label class="dbo-ui ms-4" for="nome">{{ nameLabel }}</label>
            <validation-provider
                name="nome"
                :rules="rulesName"
                v-slot="validationContext"
                vid="name"
              >
                <b-form-input
                  v-model="dataForm.name"
                  :state="getValidationState(validationContext)"
                  :placeholder="placeholderName"
                ></b-form-input>
                <b-form-invalid-feedback class="ms-4">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <label class="dbo-ui ms-4">{{ documentLabel }}</label>
              <validation-provider
                name="documento"
                :rules="rulesDocument"
                v-slot="validationContext"
                vid="document"
              >
                <b-form-input
                  v-model="dataForm.document"
                  :state="getValidationState(validationContext)"
                  :placeholder="placeholderDocument"
                  v-mask="documentMask"
                ></b-form-input>
                <b-form-invalid-feedback class="ms-4">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <label class="dbo-ui ms-4" for="nome">E-mail</label>

            <validation-provider name="email" rules="required|email" v-slot="validationContext"
                        vid="email">
              <b-form-input
                type="email"
                class="form-control"
                placeholder="Digite o e-mail para contato"
                v-model="dataForm.email"
                :state="getValidationState(validationContext)"></b-form-input>
              <b-form-invalid-feedback class="ms-4">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </validation-provider>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <label class="dbo-ui ms-4" for="nome">OAB</label>

            <validation-provider name="oab" rules="required" v-slot="validationContext"
                        vid="oab">
              <b-form-input
                class="form-control"
                placeholder="Digite o número da OAB"
                v-model="dataForm.oab"
                :state="getValidationState(validationContext)"></b-form-input>
              <b-form-invalid-feedback class="ms-4">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </validation-provider>
          </div>
          <div class="col-12 col-lg-12 mb-3">
            <label class="dbo-ui ms-4 mb-0" for="">Atividade Segurada</label>
            <p class="text-muted ms-4">Selecione uma ou mais atividades abaixo:</p>

            <div class="pretty-checkbox-group mx-4 mb-4">
              <div v-for="(value, key, index) in activityListSelected" :key="index" class="pretty-checkbox-item">
                <input type="checkbox" checked="" v-model="dataForm.activities" :value="value.id"
                        :id="'atividade-segurada-' + value.id">
                <label :for="'atividade-segurada-' + value.id">{{value.name}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-12 col-lg-12">
            <button type="submit"
            :disabled="invalid || validFields"
              class="btn btn-primary-custom rounded-pill mb-4 font-18 px-4 px-lg-7 d-inline-flex align-items-center">
              <span><strong>Próxima</strong> etapa </span>
              <i class="fa fa-angles-right ms-1 ms-lg-4 me-lg-n4 text-pink text-glow-pink" style="font-size: 1.5em;"></i>
            </button>
            <p class="text-muted">Ao enviar você concorda que o corretor poderá entrar em contato com você</p>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable */
import userTypes from "@/enums/userTypes";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { mask } from "vue-the-mask";
import { cpf, cnpj } from 'cpf-cnpj-validator';
import uiHelperMixin from '@/mixins/uiHelper';

extend('fullname', value => {
  const nameParts = value.split(' ');
  return nameParts.length > 1 || 'Você deve informar seu nome completo';
});

extend('cpf', {
  validate: value => {
    return cpf.isValid(value);
  },
  message: 'Você deve informar um CPF válido'
});

extend('cnpj', {
  validate: value => {
    return cnpj.isValid(value);
  },
  message: 'Você deve informar um CNPJ válido'
});

export default {
  name: "step-1",
  data: () => ({
    dataForm: { personType: userTypes.NATURAL,
                document: null,
                activities: [], },
    userTypes: userTypes,
    activityListPJ: [
      {id: 1, name: "Ambiental"},
      {id: 2, name: "Civil"},
      {id: 3, name: "Comercial/Empresarial"},
      {id: 4, name: "Família"},
      {id: 5, name: "Outras"},
      {id: 6, name: "Penal"},
      {id: 7, name: "Prestação de Serviços para Instituições Financeiras"},
      {id: 9, name: "Trabalhista"},
      {id: 10, name: "Tributario"},
    ],
    activityListPF: [
      {id: 1, name: "Ambiental"},
      {id: 2, name: "Civil"},
      {id: 3, name: "Comercial/Empresarial"},
      {id: 4, name: "Família"},
      {id: 5, name: "Outras"},
      {id: 6, name: "Penal"},
      {id: 7, name: "Prestação de Serviços para Instituições Financeiras"},
      {id: 8, name: "Profissionais do direito atuando como agentes públicos: Procuradores, Delegados, Juízes de Direito"},
      {id: 9, name: "Trabalhista"},
      {id: 10, name: "Tributario"},
    ]
  }),
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [uiHelperMixin],
  directives: {
    mask,
  },
  computed: {
    naturalSelected() {
      return this.dataForm.personType == userTypes.NATURAL;
    },
    legalSelected() {
      return this.dataForm.personType == userTypes.LEGAL;
    },
    activityListSelected() {
      return this.naturalSelected ? this.activityListPF : this.activityListPJ
    },
    documentLabel() {
      return this.dataForm.personType == userTypes.NATURAL
        ? "CPF"
        : "CNPJ";
    },
    nameLabel() {
      return this.dataForm.personType == userTypes.NATURAL
        ? "Nome completo"
        : "Razão social";
    },
    placeholderName() {
      return this.dataForm.personType == userTypes.NATURAL
        ? "Digite o nome completo"
        : "Digite a razão social";
    },
    placeholderDocument() {
      return this.dataForm.personType == userTypes.NATURAL
        ? "000.000.000-00"
        : "00.000.000/0000-00";
    },
    documentMask() {
      return this.dataForm.personType == userTypes.NATURAL
        ? "###.###.###-##"
        : "##.###.###/####-##";
    },
    rulesDocument() {
      return this.dataForm.personType == userTypes.NATURAL
        ? 'required|min:14|cpf'
        : 'required|min:18|cnpj'
    },
    rulesName() {
      return this.dataForm.personType == userTypes.NATURAL
        ? 'required|max:191|fullname'
        : 'required|max:191'
    },
    documentValue() {
      return this.dataForm.document;
    },
    validFields() {
      return this.dataForm.activities.length == 0
    },
  },
  mounted() {
    this.fillFields()
    this.scroll();
    this.$root.$on('reset-step-1', (bvEvent) => {
      this.resetFields()
    });
  },
  methods: {
    fillFields() {
      var fields = this.$store.getters.getLawyerFields.riskAnalysis
      this.$set(this.dataForm, 'personType', fields.personType ? fields.personType : this.userTypes.NATURAL);
      this.$set(this.dataForm, 'name', fields.name);
      this.$set(this.dataForm, 'document', fields.document);
      this.$set(this.dataForm, 'email', fields.email);
      this.$set(this.dataForm, 'oab', fields.oab);
      this.$set(this.dataForm, 'activities', fields.activities ? fields.activities : []);
    },
    async resetFields() {
      this.$set(this.dataForm, 'document', null);
      this.$set(this.dataForm, 'name', null);
      this.$set(this.dataForm, 'email', null);
      this.$set(this.dataForm, 'oab', null);
      this.$set(this.dataForm, 'activities', []);
      await this.delay(150)
      this.$set(this.dataForm, 'personType', this.userTypes.NATURAL);
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    changeToNaturalType() {
      this.$store.commit('SET_FIELDS_LAWYER_RISK_ANALYSIS', {insuredAmount: null});
      this.dataForm.personType = userTypes.NATURAL;
      this.$root.$emit('natural_person_type', () => {});
    },
    changeToLegalType() {
      this.$store.commit('SET_FIELDS_LAWYER_RISK_ANALYSIS', {insuredAmount: null});
      var index = this.dataForm.activities.indexOf(8);
      if (index !== -1) {
        this.dataForm.activities.splice(index, 1);
      }
      this.dataForm.personType = userTypes.LEGAL;
      this.$root.$emit('legal_person_type', () => {});
    },
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    quotation() {
      // limpando campos
      this.$store.commit('SET_FIELDS_LAWYER_RISK_ANALYSIS', {
        personType: null,
        name: null,
        document: null,
        email: null,
        oab: null,
        activities: null
      });

      //inputando campos
      this.$store.commit('SET_FIELDS_LAWYER_RISK_ANALYSIS', this.dataForm);
      this.redirect('step-2')
    },
  },
}
</script>
<style scoped>
@import '../../../styles/lawyer.css';

</style>
