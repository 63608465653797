<template>
  <div>

    <div v-if="alertMessage">
      <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
    </div>

    <div id="finn-loader" :class="{'loading': loading}"></div>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(quotation)" class="form" autocomplete="off">

      <div class="row">
        <div class="col-12 d-flex flex-column mb-5"
             :class="{'col-lg-6' : personType == userTypes.NATURAL, 'col-lg-4' : personType == userTypes.LEGAL, }">
          <label class="dbo-ui mx-4" for="">Início de vigência</label>

          <validation-provider name="inicio vigência" rules="required" v-slot="validationContext"
                        vid="inicio vigência">
              <b-form-input
                class="form-control rounded-4"
                type="date"
                :min="dateNow"
                v-model="dataForm.startDate"
                :state="getValidationState(validationContext)"></b-form-input>
              <b-form-invalid-feedback class="ms-4">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </validation-provider>

        </div>
        <div class="col-12 d-flex flex-column mb-5"
             :class="{'col-lg-6' : personType == userTypes.NATURAL, 'col-lg-4' : personType == userTypes.LEGAL, }">
          <label class="dbo-ui mx-4" for="">Importância segurada</label>
          <select name="importancia" id="importancia" class="form-select mt-auto" v-model="dataForm.insuredAmount">
            <option v-for="(amount, index) in insuredAmountListSelected" :key="index" :value="amount.id">
              {{amount.name}}
            </option>
          </select>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column mb-5" v-if="personType == userTypes.LEGAL">
          <label class="dbo-ui mx-4" for="">Faturamento anual</label>
          <select name="importancia" id="importancia" class="form-select mt-auto" v-model="dataForm.annualBilling">
            <option v-for="(billing, index) in annualBillingList" :key="index" :value="billing.id">
              {{billing.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 d-flex flex-column mb-5">
          <label class="dbo-ui mx-4" for="">Franquia</label>
          <p class="font-14 ms-lg-4">Participação do segurado nos prejuízos em virtude de sinistro ocorrido no local de
            risco.</p>
          <select name="franquia" id="franquia" class="form-select mt-auto" v-model="dataForm.franchise">
            <option :value="null" selected="">Selecione a franquia desejada</option>
            <option v-for="(franchise, index) in franchiseList" :key="index" :value="franchise.option">
              {{franchise.text}}
            </option>
          </select>
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column mb-5">
          <label class="dbo-ui mx-4" for="">Retroatividade</label>
          <p class="font-14 ms-lg-4">Extensão de cobertura a fatos passados.</p>

          <select name="retroatividade" id="retroatividade" class="form-select mt-auto" v-model="dataForm.retroactivity">
            <option v-for="(retroactivity, index) in retroactivityList" :key="index" :value="retroactivity.id">{{retroactivity.name}}</option>
          </select>
        </div>
      </div>

      <div class="row text-center">
        <div class="col-12 col-lg-12">

          <button type="submit"
            :disabled="invalid || validFields || validFieldsPj || loading"
            class="btn btn-primary-custom rounded-pill mb-4 font-18 px-4 px-lg-7 d-inline-flex align-items-center">
            <span><strong>Calcular</strong> cotação</span>
            <i class="fa fa-angles-right ms-1 ms-lg-4 me-lg-n4 text-pink text-glow-pink" style="font-size: 1.5em;"></i>
          </button>

        </div>
      </div>

    </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import dateFormatter from '@/mixins/dateFormatter';
import akadService from "@/services/akad";
import userTypes from "@/enums/userTypes";
import uiHelperMixin from '@/mixins/uiHelper';

export default {
  name: "step-3",
  data: () => ({
    userTypes: userTypes,
    franchiseList: [],
    dataForm: {
      insuredAmount: null,
      franchise: null,
      retroactivity: null,
      annualBilling: null
    },
    insuredAmountListPF: [
      {name: 'Selecione a importância segurada', id: null},
      {name: 'R$ 30.000,00', id: 1},
      {name: 'R$ 50.000,00 ', id: 2},
      {name: 'R$ 75.000,00 ', id: 3},
      {name: 'R$ 100.000,00', id: 4},
      {name: 'R$ 150.000,00', id: 5},
      {name: 'R$ 200.000,00', id: 6},
      {name: 'R$ 250.000,00', id: 7},
      {name: 'R$ 300.000,00', id: 8},
      {name: 'R$ 400.000,00', id: 9},
      {name: 'R$ 500.000,00', id: 10},
      {name: 'R$ 600.000,00', id: 11},
      {name: 'R$ 700.000,00', id: 12},
      {name: 'R$ 800.000,00', id: 13},
      {name: 'R$ 900.000,00', id: 14},
      {name: 'R$ 1.000.000,00', id: 15},
      {name: 'R$ 1.500.000,00', id: 16},
      {name: 'R$ 2.000.000,00', id: 17},
      {name: 'R$ 2.500.000,00', id: 18},
      {name: 'R$ 3.000.000,00', id: 19},
      {name: 'R$ 3.500.000,00', id: 20},
      {name: 'R$ 4.000.000,00', id: 21},
      {name: 'R$ 4.500.000,00', id: 22},
      {name: 'R$ 5.000.000,00', id: 23},
    ],
    insuredAmountListPJ: [
      {name: 'Selecione a importância segurada', id: null},
      { name: 'R$ 100.000,00', id: 1 },
      { name: 'R$ 150.000,00', id: 2 },
      { name: 'R$ 200.000,00', id: 3 },
      { name: 'R$ 250.000,00', id: 4 },
      { name: 'R$ 300.000,00', id: 5 },
      { name: 'R$ 400.000,00', id: 6 },
      { name: 'R$ 500.000,00', id: 7 },
      { name: 'R$ 600.000,00', id: 8 },
      { name: 'R$ 700.000,00', id: 9 },
      { name: 'R$ 800.000,00', id: 10 },
      { name: 'R$ 900.000,00', id: 11 },
      { name: 'R$ 1.000.000,00', id: 12 },
      { name: 'R$ 1.500.000,00', id: 13 },
      { name: 'R$ 2.000.000,00', id: 14 },
      { name: 'R$ 2.500.000,00', id: 15 },
      { name: 'R$ 3.000.000,00', id: 16 },
      { name: 'R$ 3.500.000,00', id: 17 },
      { name: 'R$ 4.000.000,00', id: 18 },
      { name: 'R$ 4.500.000,00', id: 19 },
      { name: 'R$ 5.000.000,00', id: 20 }
    ],
    retroactivityList: [
      {name: 'Selecione a retroatividade', id: null},
      {name: 'Até 1 ano', id: 1},
      {name: 'Até 2 anos', id: 2},
      {name: 'Até 3 anos', id: 3},
      {name: 'Até 4 anos', id: 4},
      {name: 'Até 5 anos', id: 5},
      {name: 'Sem Retroatividade', id: 6},
    ],
    annualBillingList: [
      { name: 'Selecione o faturamento anual', id: null },
      { name: 'R$ 50.000,00 a R$ 100.000,00', id: 1 },
      { name: 'R$ 100.000,00 a R$ 200.000,00', id: 2 },
      { name: 'R$ 200.000,00 a R$ 500.000,00', id: 3 },
      { name: 'R$ 500.000,00 a R$ 750.000,00', id: 4 },
      { name: 'R$ 750.000,00 a R$ 1.000.000,00', id: 5 },
      { name: 'R$ 1.000.000,00 a R$ 1.500.000,00', id: 6 },
      { name: 'R$ 1.500.000,00 a R$ 2.000.000,00', id: 7 },
      { name: 'R$ 2.000.000,00 a R$ 3.000.000,00', id: 8 },
      { name: 'R$ 3.000.000,00 a R$ 5.000.000,00', id: 9 },
      { name: 'R$ 5.000.000,00 a R$ 7.500.000,00', id: 10 },
      { name: 'R$ 7.500.000,00 a R$ 10.000.000,00', id: 11 },
    ],
    dateNow: null,
    loading: false
  }),
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [
    dateFormatter,
    uiHelperMixin
  ],
  computed: {
    validFields() {
      return this.dataForm.retroactivity == null ||
             this.dataForm.insuredAmount == null ||
             this.dataForm.franchise == null
    },
    validFieldsPj(){
      return this.personType == userTypes.LEGAL ? this.dataForm.annualBilling == null : false
    },
    personType() {
      return this.$store.getters.getLawyerFields.riskAnalysis.personType
    },
    insuredAmountListSelected() {
      return this.personType == userTypes.NATURAL ? this.insuredAmountListPF : this.insuredAmountListPJ
    }
  },
  mounted() {
    this.scroll();
    this.fillFields()
    this.dateNow = this.formatDateISO(new Date());
    this.getDeductibles()
  },
  methods: {
    fillFields() {
      var fields = this.$store.getters.getLawyerFields.riskAnalysis
      this.$set(this.dataForm, 'insuredAmount', fields.insuredAmount);
      this.$set(this.dataForm, 'franchise', fields.franchise);
      this.$set(this.dataForm, 'retroactivity', fields.retroactivity ? fields.retroactivity : 6);
      this.$set(this.dataForm, 'annualBilling', fields.annualBilling);
      this.$set(this.dataForm, 'startDate', fields.startDate ? fields.startDate : this.formatDateISO(new Date()));
    },
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    inputReturnQuotation(response) {
      this.$store.commit('SET_FIELDS_LAWYER_RISK_ANALYSIS', {
        identifier: null,
        quotationId: null,
        installmentsBillet: null,
        installmentsCreditCard: null
      });

      var installmentsBillet = null
      var installmentsCreditCard = null

      installmentsBillet = response.paymentOptions.find((item) => item.type === 'Ticket')
      installmentsCreditCard = response.paymentOptions.find((item) =>
          item.type === 'CreditCard' && item.totalAmount == item.installments[item.installments.length - 1].totalValue
        )

      if(!installmentsBillet || !installmentsCreditCard){
        console.log('Erro ao recuperar opções de pagamento')
        return
      }

      //inputando campos
      this.$store.commit('SET_FIELDS_LAWYER_RISK_ANALYSIS', {
        identifier: response.identifier,
        quotationId: response.quotationId,
        installmentsBillet: installmentsBillet,
        installmentsCreditCard: installmentsCreditCard
      });
    },
    getDeductibles() {
      this.resetAlert();
      this.loading = true
      var payload = {"questions": []}
      var data = this.$store.getters.getLawyerFields
      data.riskAnalysis.activities.forEach((item) => {
        payload.questions.push({questionId: "3", "answers": [item]})
      })
      payload.personType = data.riskAnalysis.personType

      akadService
        .getDeductibles(payload)
        .then((response) => {
          if (response.data.error) {
            console.log('Erro ao recuperar franquia')
            return;
          }
          this.franchiseList = response.data.data
          this.$store.commit('SET_FIELDS_LAWYER_RISK_ANALYSIS', {
            franchiseList: null,
          });
          this.$store.commit('SET_FIELDS_LAWYER_RISK_ANALYSIS', {franchiseList: this.franchiseList});
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as franquias.");
        }).finally(() => {
          this.loading = false
        })
    },
    quotation() {
      this.resetAlert();
      this.loading = true
      // limpando campos
      this.$store.commit('SET_FIELDS_LAWYER_RISK_ANALYSIS', {
        insuredAmount: null,
        franchise: null,
        retroactivity: null,
        startDate: null,
        annualBilling: null
      });

      //inputando campos
      this.$store.commit('SET_FIELDS_LAWYER_RISK_ANALYSIS', this.dataForm);
      var payload = this.formatPayload(this.$store.getters.getLawyerFields)

      akadService
        .quote(payload)
        .then((response) => {
          if (response.data.error) {
            console.log('Erro ao cotar')
            return;
          }
          this.inputReturnQuotation(response.data.data)
          this.redirect('step-4')
        })
        .catch(() => {
          this.showError("Não foi possível executar a cotação.");
        }).finally(() => {
          this.loading = false
        })
    },
    formatPayload(params) {
      var payload = {
        "RiskAnalysis": [
          {questionId: "1", "answer": "1"},
          {questionId: "4", "answer": params.riskAnalysis.insuredAmount.toString()},
          {questionId: "8", "answer": params.riskAnalysis.previusKnowledge.toString()},
          {questionId: "9", "answer": params.riskAnalysis.complainant},
          {questionId: "35", "answer": "1"},
        ],
        "PersonalData": {
          "Name": params.riskAnalysis.name,
          "Email": params.riskAnalysis.email,
          "Identity": params.riskAnalysis.document
        },
        "EffectiveDate": params.riskAnalysis.startDate,
        "deductibleOption": params.riskAnalysis.franchise
      }

      params.riskAnalysis.activities.forEach((item) => {
        payload.RiskAnalysis.push({questionId: "3", "answer": item.toString()})
      })

      if(params.riskAnalysis.personType == this.userTypes.NATURAL){
        payload.RiskAnalysis.push({questionId: "11", "answer": params.riskAnalysis.retroactivity.toString()})
        payload.RiskAnalysis.push({questionId: "5", "answer": params.riskAnalysis.oab.toString()})
        payload.RiskAnalysis.push({questionId: "6", "answer": params.riskAnalysis.claims.toString()})
        payload.RiskAnalysis.push({questionId: "7", "answer": params.riskAnalysis.claimsLastYear.toString()})
      }else{
        payload.RiskAnalysis.push({questionId: "11", "answer": "1"})
        payload.RiskAnalysis.push({questionId: "12", "answer": params.riskAnalysis.retroactivity.toString()})
        payload.RiskAnalysis.push({questionId: "6", "answer": params.riskAnalysis.oab.toString()})
        payload.RiskAnalysis.push({questionId: "7", "answer": params.riskAnalysis.claims.toString()})
        payload.RiskAnalysis.push({questionId: "10", "answer": params.riskAnalysis.claimsLastYear.toString()})
        payload.RiskAnalysis.push({questionId: "5", "answer": params.riskAnalysis.annualBilling.toString()})
      }

      return payload
    }
  },
}
</script>

<style scoped>@import '../../../styles/lawyer.css';</style>
