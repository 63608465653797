export default {
  methods: {
    scroll(top) {
      this.$nextTick(() => {
        window.scroll({ top: top || 50, behavior: 'smooth' });
      });
    },
    sortCompareCustom(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      // used: b-table : https://bootstrap-vue.org/docs/components/table#custom-sort-compare-routine
      let a = aRow[key];
      let b = bRow[key];

      // Date || number
      if (
        (typeof a === 'number' && typeof b === 'number') ||
        (a instanceof Date && b instanceof Date)
      ) {
        // If both compared fields are native numbers or both are native dates
        // return a < b ? -1 : a > b ? 1 : 0

      // Moeda
      } else if ((a.indexOf("R$") > -1) && (b.indexOf("R$") > -1)) {
        a = this.convertMoneyDouble(a);
        b = this.convertMoneyDouble(b);

      // Datetime pt-br
      } else if (
        (/^[0-3][0-9]\/[0-1][0-9]\/[0-9]{4} [0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/).test(a)
      ) {
        a = this.convertDatetimePtBr(a);
        b = this.convertDatetimePtBr(b);
      
      // String
      } else {
        // Otherwise stringify the field data and use String.prototype.localeCompare
        return this.toStringHelper(a).localeCompare(this.toStringHelper(b), compareLocale, compareOptions)
      }
      
      return a < b ? -1 : a > b ? 1 : 0;
    },
    convertMoneyDouble(money) {
      return parseFloat(
        String(money)
          .replace("R$", "")
          .split('.').join('')
          .split(',').join('.')
      );
    },
    convertDatetimePtBr(date) {
      let newDate = date.split(' ');
      let time = newDate[1].split(':');
      newDate = newDate[0].split('/');
      return new Date(newDate[2], newDate[1]-1, newDate[0], time[0], time[1], time[2]);
    },
    toStringHelper(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      } else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => this.toStringHelper(value[key]))
          .join(' ')
      } else {
        return String(value)
      }
    },
    isJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }
  }
}
