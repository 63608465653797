<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(quotation)" class="form" autocomplete="off">

      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="text-center">
            <div class="pill-radio mb-4">
              <div class="pill-radio-item" @click="changeToMethodBillet()"><input
                :checked="dataForm.methodPaymentBillet" type="radio" name="forma_pagamento" id="forma-boleto"
                  value="boleto" data-target-wrapper="#wrapper-dados-boleto"
                  data-wrapper-group-handler="formas-pagamento"> <label for="forma-boleto" style="margin-bottom: 0px !important;">Boleto</label></div>
              <div class="pill-radio-item" @click="changeToMethodCreditCard()"><input type="radio" name="forma_pagamento" id="forma-cartao" value="cartao"
                :checked="!dataForm.methodPaymentBillet" data-target-wrapper="#wrapper-dados-cartao" data-wrapper-group-handler="formas-pagamento">
                <label for="forma-cartao" style="margin-bottom: 0px !important;">Cartão de crédito</label></div>
            </div>
          </div>
        </div>
      </div>


      <div class="row" id="wrapper-dados-boleto" data-wrapper-group="formas-pagamento" v-if="dataForm.methodPaymentBillet">
        <div class="col-12 col-lg-12 mb-4">
          <label class="dbo-ui mx-4" for="cnpj_pagador">{{ documentLabel }} do pagador</label>
          <validation-provider
            name="documento do pagador"
            :rules="rulesDocument"
            v-slot="validationContext"
            vid="documentPayer"
          >
            <b-form-input
              v-model="dataForm.documentPayer"
              :state="getValidationState(validationContext)"
              :placeholder="placeholderDocument"
              v-mask="documentMask"
            ></b-form-input>
            <b-form-invalid-feedback class="ms-4">{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </validation-provider>
        </div>

        <div class="col-12 col-lg-12 mb-4">
          <label class="dbo-ui mx-4" for="parcelas">Número de parcelas</label>
          <select name="parcelas" id="parcelas" class="form-select" v-model="dataForm.installment">
            <option :value="null">Escolha uma opção</option>
            <option v-for="(installment, index) in installmentsBillet" :key="index" :value="installment.installmentNumber">
              {{installment.installmentNumber}}x
              {{ installment.insterestValue == 0 ? 'sem juros' : '' }}
              de {{formatCurrency(installment.totalInstallment)}}
            </option>
          </select>
        </div>

      </div>

      <div class="row" id="wrapper-dados-cartao" data-wrapper-group="formas-pagamento" v-else>
        <div class="col-12 col-lg-6 mb-4">
          <label class="dbo-ui mx-4" for="parcelas">Número de parcelas</label>
          <select name="parcelas" id="parcelas" class="form-select" v-model="dataForm.installment">
            <option :value="null">Escolha uma opção</option>
            <option v-for="(installment, index) in installmentsCreditCard" :key="index" :value="installment.installmentNumber">
              {{installment.installmentNumber}}x sem juros de {{formatCurrency(installment.totalInstallment)}}
            </option>
          </select>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <label class="dbo-ui mx-4" for="cartao">Número do cartão</label>
          <validation-provider
            name="número do cartão"
            rules="required"
            v-slot="validationContext"
            vid="cardNumber"
          >
            <b-form-input
              v-model="dataForm.cardNumber"
              :state="getValidationState(validationContext)"
              placeholder="0000 0000 0000 0000"
              v-mask="['#### #### #### ####']"
            ></b-form-input>
            <b-form-invalid-feedback class="ms-4">{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </validation-provider>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <label class="dbo-ui mx-4" for="titular">Titular do cartão</label>
          <validation-provider
            name="titular do cartão"
            rules="required|max:191|fullname"
            v-slot="validationContext"
            vid="holder"
          >
            <b-form-input
              v-model="dataForm.holder"
              :state="getValidationState(validationContext)"
              placeholder="Igual ao impresso no cartão"
            ></b-form-input>
            <b-form-invalid-feedback class="ms-4">{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </validation-provider>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <label class="dbo-ui mx-4" for="cartao">Cartão</label>
          <select name="cartao" id="cartao" class="form-select" v-model="dataForm.brand">
            <option :value="null">Escolha uma opção</option>
            <option value="2">VISA</option>
            <option value="3">MASTERCARD</option>
            <option value="4">ELO</option>
          </select>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <label class="dbo-ui mx-4" for="vencimento">Vencimento</label>
          <validation-provider
            name="vencimento"
            rules="required|cardExpiration"
            v-slot="validationContext"
            vid="cardExpiration"
          >
            <b-form-input
              v-model="dataForm.cardExpiration"
              :state="getValidationState(validationContext)"
              placeholder="00/0000"
              v-mask="['##/####']"
            ></b-form-input>
            <b-form-invalid-feedback class="ms-4">{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </validation-provider>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <label class="dbo-ui mx-4" for="ccv">CCV</label>
          <validation-provider
            name="cvv"
            rules="required"
            v-slot="validationContext"
            vid="cvv"
          >
            <b-form-input
              v-model="dataForm.cvv"
              :state="getValidationState(validationContext)"
              placeholder="000"
              v-mask="['###']"
            ></b-form-input>
            <b-form-invalid-feedback class="ms-4">{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>

      <div class="row text-center">
        <div class="col-12 col-lg-12">
          <button type="submit"
            :disabled="dataForm.methodPaymentBillet ? validFieldBillets : validFieldsCreditCard"
            class="btn btn-primary-custom rounded-pill mb-4 font-18 px-4 px-lg-7 d-inline-flex align-items-center">
            <span><strong>Próxima</strong> etapa </span>
            <i class="fa fa-angles-right ms-1 ms-lg-4 me-lg-n4 text-pink text-glow-pink" style="font-size: 1.5em;"></i>
          </button>

        </div>
      </div>

    </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { mask } from "vue-the-mask";
import numericFormatterMixin from '@/mixins/numericFormatter';
import uiHelperMixin from '@/mixins/uiHelper';
import userTypes from "@/enums/userTypes";

extend('cardExpiration', {
  validate: (value) => {
    // Use uma expressão regular para validar o formato XX/XXXX
    return /^(0[1-9]|1[0-2])\/\d{4}$/.test(value);
  },
  message: 'A data de vencimento do cartão não é válida.',
});

extend('fullname', value => {
  const nameParts = value.split(' ');
  return nameParts.length > 1 || 'Você deve informar seu nome completo';
});

extend('cnpj', {
  validate: value => {
    return cnpj.isValid(value);
  },
  message: 'Você deve informar um CNPJ válido'
});

extend('cpf', {
  validate: value => {
    return cpf.isValid(value);
  },
  message: 'Você deve informar um CPF válido'
});

export default {
  name: "step-6",
  mixins: [numericFormatterMixin, uiHelperMixin],
  data: () => ({
    dataForm: {
      documentPayer: null,
      installment: null,
      cardNumber: null,
      holder: null,
      brand: null,
      cardExpiration: null,
      cvv: null,
      methodPaymentBillet: true
    },
    userTypes: userTypes,
  }),
  computed: {
    documentLabel() {
      return this.personType == userTypes.NATURAL
        ? "CPF"
        : "CNPJ";
    },
    placeholderDocument() {
      return this.personType == userTypes.NATURAL
        ? "000.000.000-00"
        : "00.000.000/0000-00";
    },
    documentMask() {
      return this.personType == userTypes.NATURAL
        ? "###.###.###-##"
        : "##.###.###/####-##";
    },
    rulesDocument() {
      return this.personType == userTypes.NATURAL
        ? 'required|min:14|cpf'
        : 'required|min:18|cnpj'
    },
    personType() {
      return this.$store.getters.getLawyerFields.riskAnalysis.personType
    },
    validFieldsCreditCard() {
        return this.dataForm.brand == null ||
               this.dataForm.installment == null ||
               this.dataForm.cardNumber == null ||
               this.dataForm.cardExpiration == null ||
               this.dataForm.holder == null ||
               this.dataForm.cvv == null
      },
    validFieldBillets() {
      return this.dataForm.documentPayer == null || this.dataForm.installment == null
    },
    installmentsCreditCard() {
      return this.$store.getters.getLawyerFields.riskAnalysis.installmentsCreditCard.installments
    },
    installmentsBillet() {
      return this.$store.getters.getLawyerFields.riskAnalysis.installmentsBillet.installments
    }
  },
  directives: {
    mask,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  mounted() {
    this.scroll();
    this.fillFields()
  },
  methods: {
    changeToMethodBillet() {
      this.$set(this.dataForm, 'installment', null);
      this.$set(this.dataForm, 'cardNumber', null);
      this.$set(this.dataForm, 'holder', null);
      this.$set(this.dataForm, 'brand', null);
      this.$set(this.dataForm, 'cardExpiration', null);
      this.$set(this.dataForm, 'cvv', null);

      this.$store.commit('SET_FIELDS_LAWYER_MAKE_POLICY', {
        installment: null,
        cardNumber: null,
        holder: null,
        brand: null,
        cardExpiration: null,
        cvv: null,
      });
      this.dataForm.methodPaymentBillet = true;
    },
    changeToMethodCreditCard() {
      this.$set(this.dataForm, 'documentPayer', null);
      this.$store.commit('SET_FIELDS_LAWYER_MAKE_POLICY', {
        documentPayer: null,
      });
      this.dataForm.methodPaymentBillet = false;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
    fillFields() {
      var fields = this.$store.getters.getLawyerFields.makePolicy
      //this.$set(this.dataForm, 'documentPayer', fields.documentPayer);
      //this.$set(this.dataForm, 'installment', fields.installment);
      //this.$set(this.dataForm, 'cardNumber', fields.cardNumber);
      //this.$set(this.dataForm, 'holder', fields.holder);
      //this.$set(this.dataForm, 'brand', fields.brand);
      //this.$set(this.dataForm, 'cardExpiration', fields.cardExpiration);
      //this.$set(this.dataForm, 'cvv', fields.cvv);
      this.$set(this.dataForm, 'methodPaymentBillet', fields.methodPaymentBillet != null ? fields.methodPaymentBillet : true);
      // limpando campos
      this.$store.commit('SET_FIELDS_LAWYER_MAKE_POLICY', {
        documentPayer: null,
        installment: null,
        cardNumber: null,
        holder: null,
        brand: null,
        cardExpiration: null,
        cvv: null,
      });
    },
    quotation() {
      // limpando campos
      this.$store.commit('SET_FIELDS_LAWYER_MAKE_POLICY', {
        documentPayer: null,
        installment: null,
        cardNumber: null,
        holder: null,
        brand: null,
        cardExpiration: null,
        cvv: null,
        methodPaymentBillet: null
      });

      //inputando campos
      this.$store.commit('SET_FIELDS_LAWYER_MAKE_POLICY', this.dataForm);
      this.redirect('step-7')
    },
  },
}
</script>

<style scoped>@import '../../../styles/lawyer.css';</style>
