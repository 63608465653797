<template>
  <div>
    <div id="finn-loader" :class="{'loading': loading}"></div>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(quotation)" class="form" autocomplete="off">

      <div class="row">
        <div class="col-12 col-lg-6 mb-4">
          <label class="dbo-ui mx-4" for="cep">CEP</label>´
          <validation-provider
                name="cep"
                rules="required"
                v-slot="validationContext"
                vid="zipcode"
                v-model="dataForm.zipcode"
              >
                <b-form-input
                  v-mask="cepMask"
                  v-model="dataForm.zipcode"
                  placeholder="00000-000"
                  @blur="searchCepSimple()"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-spinner small v-if="loadingCep"></b-spinner>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <label class="dbo-ui mx-4" for="logradouro">Logradouro</label>
          <validation-provider
                name="logradouro"
                rules="required"
                v-slot="validationContext"
                vid="address"
                v-model="dataForm.address"
              >
                <b-form-input
                  v-model="dataForm.address"
                  placeholder="Digite a rua, avenida, via, etc."
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <label class="dbo-ui mx-4" for="numero">Número</label>

          <validation-provider
                name="número"
                rules="required"
                v-slot="validationContext"
                vid="number"
                v-model="dataForm.address"
              >
                <b-form-input
                  v-model="dataForm.number"
                  placeholder="Digite o número"
                  ref="address_number"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>

        </div>
        <div class="col-12 col-lg-6 mb-4">
          <label class="dbo-ui mx-4" for="complemento">Complemento</label>

          <validation-provider
                name="complemento"
                rules=""
                v-slot="validationContext"
                vid="complement"
                v-model="dataForm.complement"
              >
                <b-form-input
                  v-model="dataForm.complement"
                  placeholder="Opcional"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>

        </div>
        <div class="col-12 col-lg-4 mb-4">
          <label class="dbo-ui mx-4" for="bairro">Bairro</label>
          <validation-provider
                name="bairro"
                rules="required"
                v-slot="validationContext"
                vid="neighborhood"
                v-model="dataForm.neighborhood"
              >
                <b-form-input
                  v-model="dataForm.neighborhood"
                  placeholder="Bairro"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>

        </div>
        <div class="col-12 col-lg-4 mb-4">
          <label class="dbo-ui mx-4" for="cidade">Cidade</label>
          <validation-provider
                name="cidade"
                rules="required"
                v-slot="validationContext"
                vid="city"
                v-model="dataForm.city"
              >
                <b-form-input
                  v-model="dataForm.city"
                  placeholder="Cidade"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </div>
        <div class="col-12 col-lg-4 mb-5">
          <label class="dbo-ui mx-4" for="estado">Estado</label>
          <select name="estado" id="estado" class="form-select" v-model="dataForm.uf">
            <option v-for="(state, index) in states" :key="index" :value="state.id">
              {{state.name}}
            </option>
          </select>

        </div>
      </div>

      <div class="row text-center">
        <div class="col-12 col-lg-12">

          <button type="submit"
            :disabled="invalid || validFields"
            class="btn btn-primary-custom rounded-pill mb-4 font-18 px-4 px-lg-7 d-inline-flex align-items-center">
            <span><strong>Próxima</strong> etapa </span>
            <i class="fa fa-angles-right ms-1 ms-lg-4 me-lg-n4 text-pink text-glow-pink" style="font-size: 1.5em;"></i>
          </button>

        </div>
      </div>

    </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
import uiHelperMixin from '@/mixins/uiHelper';
import commonsService from "@/services/commons";

export default {
  name: "step-5",
  mixins: [uiHelperMixin],
  data: () => ({
    dataForm: {
      zipcode: null,
      address: null,
      complement: null,
      number: null,
      neighborhood: null,
      city: null,
      uf: null,
    },
    loadingCep: false,
    states: [
        { id: null, name: 'UF' },
        { id: 'AC', name: 'Acre' },
        { id: 'AL', name: 'Alagoas' },
        { id: 'AP', name: 'Amapá' },
        { id: 'AM', name: 'Amazonas' },
        { id: 'BA', name: 'Bahia' },
        { id: 'CE', name: 'Ceará' },
        { id: 'DF', name: 'Distrito Federal' },
        { id: 'ES', name: 'Espírito Santo' },
        { id: 'GO', name: 'Goiás' },
        { id: 'MA', name: 'Maranhão' },
        { id: 'MT', name: 'Mato Grosso' },
        { id: 'MS', name: 'Mato Grosso do Sul' },
        { id: 'MG', name: 'Minas Gerais' },
        { id: 'PA', name: 'Pará' },
        { id: 'PB', name: 'Paraíba' },
        { id: 'PR', name: 'Paraná' },
        { id: 'PE', name: 'Pernambuco' },
        { id: 'PI', name: 'Piauí' },
        { id: 'RJ', name: 'Rio de Janeiro' },
        { id: 'RN', name: 'Rio Grande do Norte' },
        { id: 'RS', name: 'Rio Grande do Sul' },
        { id: 'RO', name: 'Rondônia' },
        { id: 'RR', name: 'Roraima' },
        { id: 'SC', name: 'Santa Catarina' },
        { id: 'SP', name: 'São Paulo' },
        { id: 'SE', name: 'Sergipe' },
        { id: 'TO', name: 'Tocantins' }
      ],
    loading: false
  }),
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  computed: {
    cepMask() {
      return "#####-###";
    },
    validFields() {
      return this.dataForm.uf == null
    },
  },
  directives: {
    mask,
  },
  mounted() {
    this.scroll();
    this.fillFields()
  },
  methods: {
    searchCepSimple() {
      this.loading = true
      commonsService.findCEPSimple(this.dataForm.zipcode).then(response => {
        this.dataForm.neighborhood = response.data.data.bairro
        this.dataForm.address = response.data.data.logradouro
        this.dataForm.city = response.data.data.localidade
        this.dataForm.uf = response.data.data.uf
        this.$refs.address_number.focus();
      }).catch(() => {
        console.log('Não foi possível encontrar dados desse cep.');
      }).finally(() => {
        this.loading = false;
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
    fillFields() {
      var fields = this.$store.getters.getLawyerFields.makePolicy
      this.$set(this.dataForm, 'zipcode', fields.zipcode);
      this.$set(this.dataForm, 'address', fields.address);
      this.$set(this.dataForm, 'complement', fields.complement);
      this.$set(this.dataForm, 'number', fields.number);
      this.$set(this.dataForm, 'neighborhood', fields.neighborhood);
      this.$set(this.dataForm, 'city', fields.city);
      this.$set(this.dataForm, 'uf', fields.uf);
    },
    quotation() {
      // limpando campos
      this.$store.commit('SET_FIELDS_LAWYER_MAKE_POLICY', {
        zipcode: null,
        address: null,
        district: null,
        complement: null,
        number: null,
        neighborhood: null,
        city: null,
        uf: null,
      });

      //inputando campos
      this.$store.commit('SET_FIELDS_LAWYER_MAKE_POLICY', this.dataForm);
      this.redirect('step-6')
    }
  },
}
</script>

<style scoped>
@import '../../../styles/lawyer.css';
</style>
