<template>
  <div>
    <div v-if="alertMessage">
      <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
    </div>
    <div id="finn-loader" :class="{'loading': loading}"></div>
    <form>

      <div class="row mx-auto" style="max-width: 40rem;">
        <div
          class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center px-4 p-3 rounded-2 fw-semibold bg-light-subtle">
          <div class="text-muted font-14">Segurado:</div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance">{{ lawyerData.riskAnalysis.name }}</div>
        </div>
        <div class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center px-4 p-3 rounded-2 fw-semibold">
          <div class="text-muted font-14">
            <template v-if="lawyerData.riskAnalysis.personType == 1">
              CPF:
            </template>
            <template v-else>
              CNPJ:
            </template>
          </div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance">{{ lawyerData.riskAnalysis.document }}</div>
        </div>
        <div
          class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center px-4 p-3 rounded-2 fw-semibold bg-light-subtle">
          <div class="text-muted font-14">E-mail:</div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance">{{ lawyerData.riskAnalysis.email }}</div>
        </div>
        <div class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center px-4 p-3 rounded-2 fw-semibold ">
          <div class="text-muted font-14">OAB:</div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance">{{ lawyerData.riskAnalysis.oab }}</div>
        </div>
        <div
          class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center px-4 p-3 rounded-2 fw-semibold bg-light-subtle">
          <div class="text-muted font-14">Atividade:</div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance">
            {{formatActivities}}
          </div>
        </div>
        <div class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center px-4 p-3 rounded-2 fw-semibold ">
          <div class="text-muted font-14">Início da apólice:</div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance">{{ formatDateLocal(lawyerData.riskAnalysis.startDate) }}</div>
        </div>
        <div
          class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center px-4 p-3 rounded-2 fw-semibold bg-light-subtle">
          <div class="text-muted font-14">Franquia:</div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance">
            {{lawyerData.riskAnalysis.franchiseList.find((item) => item.option === lawyerData.riskAnalysis.franchise).text }}
          </div>
        </div>
        <div class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center px-4 p-3 rounded-2 fw-semibold ">
          <div class="text-muted font-14">Importância segurada:</div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance">
            {{lawyerData.riskAnalysis.personType == 1 ?
              insuredAmountListPF.find((item) => item.id === lawyerData.riskAnalysis.insuredAmount).name :
              insuredAmountListPJ.find((item) => item.id === lawyerData.riskAnalysis.insuredAmount).name
            }}
          </div>
        </div>
        <div
          class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center px-4 p-3 rounded-2 fw-semibold bg-light-subtle">
          <div class="text-muted font-14">Retroatividade:</div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance">
            {{retroactivityList.find((item) => item.id === lawyerData.riskAnalysis.retroactivity).name}}
          </div>
        </div>
        <div class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center px-4 p-3 rounded-2 fw-semibold ">
          <div class="text-muted font-14">Ocorrência de sinistros:</div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance">
            {{claimsList.find((item) => item.value === lawyerData.riskAnalysis.claims).name}}
          </div>
        </div>
        <div
          class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center px-4 p-3 rounded-2 fw-semibold bg-light-subtle">
          <div class="text-muted font-14">Conhecimento prévio:</div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance">{{ lawyerData.riskAnalysis.previusKnowledge == 1 ? 'Sim' : 'Não' }}</div>
        </div>
        <div class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center px-4 p-3 rounded-2 fw-semibold ">
          <div class="text-muted font-14">Forma de pagamento:</div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance"> {{ lawyerData.makePolicy.methodPaymentBillet ? 'Boleto' : 'Cartão de crédito' }}</div>
        </div>
        <div
          class="col-12 col-lg-12 d-flex flex-column flex-md-row align-items-center align-items-md-start px-4 p-3 rounded-2 fw-semibold bg-light-subtle mb-5">
          <div class="text-muted font-14">Prêmio total:</div>
          <div class="ms-md-auto text-center text-md-end text-wrap-balance lh-sm">
            <span class="font-30 text-pink fw-semibold">
              {{lawyerData.makePolicy.methodPaymentBillet ?
                formatCurrency(lawyerData.riskAnalysis.installmentsBillet.installments[lawyerData.makePolicy.installment - 1].totalValue) :
                formatCurrency(lawyerData.riskAnalysis.installmentsBillet.totalAmount)}}

            </span> <br>
            Em
            {{ lawyerData.makePolicy.installment }}x de
            {{ lawyerData.makePolicy.methodPaymentBillet ?
            formatCurrency(lawyerData.riskAnalysis.installmentsBillet.installments[lawyerData.makePolicy.installment - 1].totalInstallment) :
            formatCurrency(lawyerData.riskAnalysis.installmentsCreditCard.installments[lawyerData.makePolicy.installment - 1].totalInstallment) }}
          </div>
        </div>
      </div>

      <div class="row text-center">
        <div class="col-12 col-lg-12">

          <button
            :disabled="loading"
            @click="quotation"
            class="btn btn-primary-custom rounded-pill mb-4 font-18 px-4 px-lg-7 d-inline-flex align-items-center">
            <span><strong>Contratar</strong> agora</span>
            <i class="fa fa-angles-right ms-1 ms-lg-4 me-lg-n4 text-pink text-glow-pink" style="font-size: 1.5em;"></i>
          </button>

        </div>
      </div>

    </form>
  </div>
</template>

<script>
import dateFormatter from "@/mixins/dateFormatter";
import akadService from "@/services/akad";
import uiHelperMixin from '@/mixins/uiHelper';
import numericFormatterMixin from '@/mixins/numericFormatter';

export default {
  name: "step-7",
  mixins: [dateFormatter, uiHelperMixin, numericFormatterMixin],
  computed: {
    formatActivities(){
      var activities = this.activityList.filter((item) => this.lawyerData.riskAnalysis.activities.includes(item.id))
      var namesActivities = activities.map((item) => item.name);
      return namesActivities.join(', ');
    }
  },
  data: () => ({
    lawyerData: {},
    activityList: [
      {id: 1, name: "Ambiental"},
      {id: 2, name: "Civil"},
      {id: 3, name: "Comercial/Empresarial"},
      {id: 4, name: "Família"},
      {id: 5, name: "Outras"},
      {id: 6, name: "Penal"},
      {id: 7, name: "Prestação de Serviços para Instituições Financeiras"},
      {id: 8, name: "Profissionais do direito atuando como agentes públicos: Procuradores, Delegados, Juízes de Direito"},
      {id: 9, name: "Trabalhista"},
      {id: 10, name: "Tributario"},
    ],
    claimsList: [
      {name: 'Selecione os sinistros nos últimos anos', value: null},
      {name: 'Nenhum', value: 1},
      {name: '01 Sinistro', value: 2},
      {name: '02 Sinistros', value: 3},
      {name: '03 ou mais', value: 4},
    ],
    retroactivityList: [
      {name: 'Selecione a retroatividade', id: null},
      {name: 'Até 1 ano', id: 1},
      {name: 'Até 2 anos', id: 2},
      {name: 'Até 3 anos', id: 3},
      {name: 'Até 4 anos', id: 4},
      {name: 'Até 5 anos', id: 5},
      {name: 'Sem Retroatividade', id: 6},
    ],
    insuredAmountListPF: [
      {name: 'Selecione a importância segurada', id: null},
      {name: 'R$ 30.000,00', id: 1},
      {name: 'R$ 50.000,00 ', id: 2},
      {name: 'R$ 75.000,00 ', id: 3},
      {name: 'R$ 100.000,00', id: 4},
      {name: 'R$ 150.000,00', id: 5},
      {name: 'R$ 200.000,00', id: 6},
      {name: 'R$ 250.000,00', id: 7},
      {name: 'R$ 300.000,00', id: 8},
      {name: 'R$ 400.000,00', id: 9},
      {name: 'R$ 500.000,00', id: 10},
      {name: 'R$ 600.000,00', id: 11},
      {name: 'R$ 700.000,00', id: 12},
      {name: 'R$ 800.000,00', id: 13},
      {name: 'R$ 900.000,00', id: 14},
      {name: 'R$ 1.000.000,00', id: 15},
      {name: 'R$ 1.500.000,00', id: 16},
      {name: 'R$ 2.000.000,00', id: 17},
      {name: 'R$ 2.500.000,00', id: 18},
      {name: 'R$ 3.000.000,00', id: 19},
      {name: 'R$ 3.500.000,00', id: 20},
      {name: 'R$ 4.000.000,00', id: 21},
      {name: 'R$ 4.500.000,00', id: 22},
      {name: 'R$ 5.000.000,00', id: 23},
    ],
    insuredAmountListPJ: [
      {name: 'Selecione a importância segurada', id: null},
      { name: 'R$ 100.000,00', id: 1 },
      { name: 'R$ 150.000,00', id: 2 },
      { name: 'R$ 200.000,00', id: 3 },
      { name: 'R$ 250.000,00', id: 4 },
      { name: 'R$ 300.000,00', id: 5 },
      { name: 'R$ 400.000,00', id: 6 },
      { name: 'R$ 500.000,00', id: 7 },
      { name: 'R$ 600.000,00', id: 8 },
      { name: 'R$ 700.000,00', id: 9 },
      { name: 'R$ 800.000,00', id: 10 },
      { name: 'R$ 900.000,00', id: 11 },
      { name: 'R$ 1.000.000,00', id: 12 },
      { name: 'R$ 1.500.000,00', id: 13 },
      { name: 'R$ 2.000.000,00', id: 14 },
      { name: 'R$ 2.500.000,00', id: 15 },
      { name: 'R$ 3.000.000,00', id: 16 },
      { name: 'R$ 3.500.000,00', id: 17 },
      { name: 'R$ 4.000.000,00', id: 18 },
      { name: 'R$ 4.500.000,00', id: 19 },
      { name: 'R$ 5.000.000,00', id: 20 }
    ],
    loading: false
  }),
  created() {
    this.lawyerData = this.$store.getters.getLawyerFields
  },
  mounted() {
    this.scroll();
  },
  methods: {
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
    quotation() {
      this.resetAlert();
      this.loading = true
      var lawyerFields = this.$store.getters.getLawyerFields
      var payload = this.formatPayload(lawyerFields)


      akadService
        .issuePolicy(lawyerFields.riskAnalysis.quotationId, payload)
        .then((response) => {
          if (response.data.error) {
            console.log('Erro ao cotar')
            return;
          }
          this.redirect('step-8')
        })
        .catch(() => {
          this.showError("Não foi possível executar a cotação.");
        }).finally(() => {
          this.loading = false
        })

    },
    formatPayload(params) {
      var payload = {
        "proposal": {
          "Identifier": params.riskAnalysis.identifier,
          "PersonalData": {
            "name": params.riskAnalysis.name,
            "firstName": params.riskAnalysis.name.split(" ")[0],
            "lastName": params.riskAnalysis.name.split(" ")[params.riskAnalysis.name.split(" ").length - 1],
            "identity": params.riskAnalysis.document,
            "email": params.riskAnalysis.email,
            "gender": params.makePolicy.gender,
            "birthDate": params.makePolicy.birthDate,
            "phoneNumber": params.makePolicy.phone,
            "zipCode": params.makePolicy.zipcode,
            "address": params.makePolicy.address,
            "neighborhood": params.makePolicy.neighborhood,
            "number": params.makePolicy.number,
            "state": params.makePolicy.uf,
            "city": params.makePolicy.city,
            "complement": params.makePolicy.complement
          }
        },
        "payment": {
          "policyData": null,
          "GeneralConditionsAgreed": true,
          "paymentOption": {
              "paymentMethodId": params.makePolicy.methodPaymentBillet ? 1 : params.makePolicy.brand,
              "installmentNumber": params.makePolicy.installment,
              "cardName": params.makePolicy.methodPaymentBillet ? "" : params.makePolicy.holder, //Nome impresso cartão
              "cardNumber": params.makePolicy.methodPaymentBillet ? null :params.makePolicy.cardNumber, //Numero do Cartão
              "cardExpirationDate": params.makePolicy.methodPaymentBillet ? "" : params.makePolicy.cardExpiration, //Data de Vencimento do Cartão
              "cardSecurityCode": params.makePolicy.methodPaymentBillet ? "" : params.makePolicy.cvv //Codigo de Segurança do Cartão
          }
        },
        "PEPData": {
            "PEP": params.makePolicy.exposedPerson == 1 ? true : false,
            "IDontWantToFillInTheInformation": !params.makePolicy.showMoreFields ? false : true,
            "EstimatedPatrimony": params.makePolicy.worthEstimated > 0 ? params.makePolicy.worthEstimated : null,
            "FinantialSituation": params.makePolicy.incomeMonthly > 0 ? params.makePolicy.incomeMonthly : null,
            "Profession": params.makePolicy.profession,
            "PhoneNumber": params.makePolicy.phone,
            "Address": params.makePolicy.address + ", " +
                       params.makePolicy.number + " " +
                       params.makePolicy.complement + " - " +
                       params.makePolicy.neighborhood + ", " +
                       params.makePolicy.city + " - " +
                       params.makePolicy.uf + ", " +
                       params.makePolicy.zipcode,
            "BirthDate": params.makePolicy.birthDate
        }
      }

      return payload
    }
  },
}
</script>

<style scoped>@import '../../../styles/lawyer.css';</style>
