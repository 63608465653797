<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(quotation)" class="form" autocomplete="off">

      <div class="row">
        <div class="col-12 col-lg-6 d-flex flex-column mb-5">
          <label class="dbo-ui mx-4" for="">Sinistros nos últimos 5 anos</label>
          <p class="font-14 mx-4">O segurado já sofreu qualquer tipo de reclamação extrajudicial, processo judicial civil,
            criminal ou ético administrativo por dano(s) causado(s) pela prestação de seus serviços nos últimos 5 anos?
            <strong>Se</strong> sim, quantas?</p>
          <select name="sinistros" id="sinistros" class="form-select mt-auto" v-model="dataForm.claims">
            <option v-for="(claim, index) in claimsList" :key="index" :value="claim.value">{{claim.name}}</option>
          </select>
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column mb-5">
          <label class="dbo-ui mx-4" for="">Conhecimento prévio</label>
          <p class="font-14 mx-4">O segurado tem conhecimento de qualquer fato ou circunstância que possa gerar uma
            reclamação extrajudicial, processo judicial civil, criminal ou ético administrativo ou de qualquer tipo
            similar de pedido de reparação de dano(s) causado(s) pela prestação de serviços?</p>
          <select name="sinistros" id="sinistros" class="form-select mt-auto" v-model="dataForm.previusKnowledge">
            <option :value="null">Selecione se possui conhecimento</option>
            <option :value="1">Sim</option>
            <option :value="2">Não</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 d-flex flex-column mb-5">
          <label class="dbo-ui mx-4" for="">Sinistros nos últimos 12 meses</label>
          <p class="font-14 mx-4">Informe o número de reclamações que você recebeu no último ano.</p>
          <select name="sinistros" id="sinistros" class="form-select" v-model="dataForm.claimsLastYear">
            <option v-for="(claim, index) in claimsLastYearList" :key="index" :value="claim.value">{{claim.name}}</option>
          </select>
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column mb-5">
          <label class="dbo-ui mx-4" for="">Reclamantes</label>
          <p class="font-14 mx-4">Por favor, informe o nome do(s) potencial(is) terceiro(s) reclamante(s):</p>


          <validation-provider name="reclamantes" :rules="rulesComplainant" v-slot="validationContext"
                        vid="reclamantes">
              <b-form-textarea
                class="form-control rounded-4"
                placeholder="Digite os nomes dos potenciais reclamantes"
                v-model="dataForm.complainant"
                rows="5"
                :state="getValidationState(validationContext)"></b-form-textarea>
              <b-form-invalid-feedback class="ms-4">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </validation-provider>
        </div>
      </div>

      <div class="row text-center">
        <div class="col-12 col-lg-12">

          <button type="submit"
            :disabled="invalid || validFields"
            class="btn btn-primary-custom rounded-pill mb-4 font-18 px-4 px-lg-7 d-inline-flex align-items-center">
            <span><strong>Próxima</strong> etapa </span>
            <i class="fa fa-angles-right ms-1 ms-lg-4 me-lg-n4 text-pink text-glow-pink" style="font-size: 1.5em;"></i>
          </button>

        </div>
      </div>
    </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import uiHelperMixin from '@/mixins/uiHelper';

export default {
  name: "step-2",
  data: () => ({
    dataForm: {
      claims: null,
      previusKnowledge: null,
      claimsLastYear: null
    },
    claimsList: [
      {name: 'Selecione os sinistros nos últimos anos', value: null},
      {name: 'Nenhum', value: 1},
      {name: '01 Sinistro', value: 2},
      {name: '02 Sinistros', value: 3},
      {name: '03 ou mais', value: 4},
    ],
    claimsLastYearList: [
      {name: 'Selecione os sinistros nos últimos 12 meses', value: null},
      {name: 'Nenhum', value: 1},
      {name: '01 Reclamação', value: 2},
      {name: '02 ou mais', value: 3},
    ],
  }),
  mixins: [uiHelperMixin],
  computed: {
    validFields() {
      return this.dataForm.claims == null || this.dataForm.previusKnowledge == null
      || this.dataForm.claimsLastYear == null || this.dataForm.claimsLastYear == 3 || this.dataForm.claims == 4
    },
    rulesComplainant() {
      return this.dataForm.previusKnowledge == 1
        ? 'required'
        : ''
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  mounted() {
    this.scroll();
    this.fillFields()
  },
  methods: {
    fillFields() {
      var fields = this.$store.getters.getLawyerFields.riskAnalysis
      this.$set(this.dataForm, 'claims', fields.claims);
      this.$set(this.dataForm, 'previusKnowledge', fields.previusKnowledge);
      this.$set(this.dataForm, 'claimsLastYear', fields.claimsLastYear);
      this.$set(this.dataForm, 'complainant', fields.complainant);
    },
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
    quotation() {
      // limpando campos
      this.$store.commit('SET_FIELDS_LAWYER_RISK_ANALYSIS', {
        claims: null,
        previusKnowledge: null,
        claimsLastYear: null,
        complainant: null
      });

      //inputando campos
      this.$store.commit('SET_FIELDS_LAWYER_RISK_ANALYSIS', this.dataForm);
      this.redirect('step-3')
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
}
</script>

<style scoped>
@import '../../../styles/lawyer.css';
</style>
