<template>
  <main>
    <div class="card box-giga-glow-pink">
      <div class="card-body p-3 p-lg-5">
        <WizardNavigator
          :steps="steps"
          :routeIndexes="routeIndexes"
        />
        <router-view/>
      </div>
    </div>
  </main>
</template>

<script>
import WizardNavigator from '@/components/Lawyer/WizardNavigator';

export default {
  name: "WizardLawyer",
  components: {
    WizardNavigator,
  },
  data: () => ({
    steps: [
      "Cotação e Contratação",
      "Informações de risco",
      "Informações gerais",
      "Cotação concluída",
      "Endereço",
      "Método de pagamento",
      "Confirme seus dados",
      "",
    ],
    routeIndexes: {
      'step-1': null,
      'step-2': 2,
      'step-3': 3,
      'step-4': 4,
      'step-5': 5,
      'step-6': 6,
      'step-7': 7,
      'step-8': 8,
    }
  }),
};
</script>

<style scoped></style>
