<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(quotation)" class="form" autocomplete="off">

      <div class="row">
        <div class="col-12 col-lg-12">
          <div
            class="banner-contratacao mb-4 mt-n3 d-flex flex-column flex-lg-row gap-4 align-items-center lh-sm text-wrap-balance px-6 text-center">
            <div class="font-24 fw-semibold text-lg-start">Valor total do seguro</div>
            <div class="font-40 font-lg-60 text-pink text-glow-pink nowrap fw-semibold">
              {{formatCurrency(installments.installmentsBillet.totalAmount)}}
            </div>
            <div class="font-18 ms-lg-auto text-lg-end">Em até

              {{ installments.installmentsCreditCard.installments.length }}x sem juros de
              {{ formatCurrency(installments.installmentsCreditCard.installments[
                installments.installmentsCreditCard.installments.length-1
              ].totalInstallment) }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-12">
          <h2 class="text-center mb-5">Para contratar, preencha abaixo:</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 d-flex flex-column mb-5" v-if="validFieldsPF">
          <label class="dbo-ui mx-4" for="data_nascimento">Data de nascimento</label>

          <validation-provider name="data de nascimento" rules="required" v-slot="validationContext"
                        vid="data de nascimento">
              <b-form-input
                class="form-control rounded-4"
                type="date"
                v-model="dataForm.birthDate"
                :max="maxDate"
                :state="getValidationState(validationContext)"></b-form-input>
              <b-form-invalid-feedback class="ms-4">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </validation-provider>
        </div>
        <div class=" d-flex flex-column mb-5" :class="{'col-12 col-lg-12' : !validFieldsPF, 'col-12 col-lg-6' :  validFieldsPF}">
          <label class="dbo-ui mx-4" for="telefone">Telefone</label>

          <validation-provider name="telefone" rules="required" v-slot="validationContext"
                        vid="telefone">
              <b-form-input
                class="form-control rounded-4"
                v-mask="['(##) ####-####', '(##) #####-####']"
                v-model="dataForm.phone"
                :state="getValidationState(validationContext)"></b-form-input>
              <b-form-invalid-feedback class="ms-4">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </validation-provider>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 d-flex flex-column mb-5">
          <label class="dbo-ui mx-4" for="genero">Gênero</label>
          <select name="genero" id="genero" class="form-select" v-model="dataForm.gender">
            <option :value="null" >Selecione o gênero</option>
            <option value="M" >Masculino</option>
            <option value="F" >Feminino</option>
          </select>
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column mb-5">
          <label class="dbo-ui mx-4" for="pessoa_publicamente_exposta">Pessoa politicamente exposta</label>
          <select name="pessoa_publicamente_exposta" id="pessoa_publicamente_exposta" class="form-select mb-3" v-model="dataForm.exposedPerson">
            <option :value="null" selected="">Selecione...</option>
            <option :value="1">Sim</option>
            <option :value="2">Não</option>
          </select>
          <p class="font-14 text-muted mx-4">Para finalizar a contratação do seguro, você deverá informar se nos últimos 5
            anos você ou algum de seus familiares desempenhou cargo público ou posição relevante em empresas públicas do
            Governo Federal.</p>
        </div>
      </div>

      <div class="row mb-6 mt-lg-n7">
        <div class="col-12 col-lg-12">
          <div class="pretty-checkbox-item">
            <input type="checkbox" name="nao_informar_dados_sensiveis" id="nao_informar_dados_sensiveis" v-model="dataForm.showMoreFields"><label
              for="nao_informar_dados_sensiveis">Não quero informar os dados abaixo.</label>
          </div>
        </div>
      </div>

      <div class="row" id="wrapper-dados-sensiveis" v-if="!dataForm.showMoreFields">
        <div class="col-12 col-lg-4 mb-5">
          <label class="dbo-ui mx-4" for="patrimonio_estimado">Patrimônio estimado</label>

          <validation-provider
            name="patrimônio estimado"
            rules="required"
            v-slot="validationContext"
          >
            <money
              v-bind="money"
              v-model="dataForm.worthEstimated"
              :state="getValidationState(validationContext)"
              @keypress.native="getValidationLess($event)"
              class="form-control"
            />
            <b-form-invalid-feedback>{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </validation-provider>
        </div>
        <div class="col-12 col-lg-4 mb-5">
          <label class="dbo-ui mx-4" for="renda_mensal">Renda mensal</label>

          <validation-provider
            name="renda mensal"
            rules="required"
            v-slot="validationContext"
          >
            <money
              v-bind="money"
              v-model="dataForm.incomeMonthly"
              :state="getValidationState(validationContext)"
              @keypress.native="getValidationLess($event)"
              class="form-control"
            />
            <b-form-invalid-feedback>{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </validation-provider>

        </div>
        <div class="col-12 col-lg-4 mb-5">
          <label class="dbo-ui mx-4" for="profissao">Profissão</label>
          <validation-provider name="profissão" rules="required" v-slot="validationContext"
                        vid="profissão">
              <b-form-input
                class="form-control rounded-4"
                v-model="dataForm.profession"
                :state="getValidationState(validationContext)"></b-form-input>
              <b-form-invalid-feedback class="ms-4">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </validation-provider>
        </div>
      </div>

      <div class="row text-center">
        <div class="col-12 col-lg-12">

          <button type="submit"
            :disabled="invalid || validFields || validValues"
            class="btn btn-primary-custom rounded-pill mb-4 font-18 px-4 px-lg-7 d-inline-flex align-items-center">
            <span><strong>Próxima</strong> etapa </span>
            <i class="fa fa-angles-right ms-1 ms-lg-4 me-lg-n4 text-pink text-glow-pink" style="font-size: 1.5em;"></i>
          </button>

        </div>
      </div>

    </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
import numericFormatterMixin from '@/mixins/numericFormatter';
import uiHelperMixin from '@/mixins/uiHelper';
import userTypes from "@/enums/userTypes";

export default {
  name: "step-4",
  mixins: [numericFormatterMixin, uiHelperMixin],
  data: () => ({
    dataForm: {
      birthDate: null,
      phone: null,
      gender: null,
      exposedPerson: null,
      worthEstimated: 0,
      incomeMonthly: 0,
      profession: null,
      showMoreFields: false
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: " ",
      precision: 2,
      masked: false,
    },
    userTypes: userTypes,
  }),
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    mask,
  },
  computed: {
    validFields() {
      return this.dataForm.gender == null || this.dataForm.exposedPerson == null
    },
    validValues() {
      return !this.dataForm.showMoreFields ? this.dataForm.incomeMonthly == 0 || this.dataForm.worthEstimated == 0 : false
    },
    installments() {
      return this.$store.getters.getLawyerFields.riskAnalysis
    },
    validFieldsPF(){
      return this.personType == userTypes.NATURAL
    },
    personType() {
      return this.$store.getters.getLawyerFields.riskAnalysis.personType
    },
    maxDate() {
      const currentDate = new Date();

      // Subtraia 18 anos da data atual
      currentDate.setFullYear(currentDate.getFullYear() - 18);

      // Formate a data como 'YYYY-MM-DD'
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate
    },
  },
  mounted() {
    this.scroll();
    this.fillFields()
  },
  methods: {
    getValidationLess(event) {
      return event.key === '-' ? event.preventDefault() : null
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
    fillFields() {
      var fields = this.$store.getters.getLawyerFields.makePolicy
      this.$set(this.dataForm, 'birthDate', this.validFieldsPF ? fields.birthDate : null);
      this.$set(this.dataForm, 'phone', fields.phone);
      this.$set(this.dataForm, 'gender', fields.gender);
      this.$set(this.dataForm, 'exposedPerson', fields.exposedPerson);
      this.$set(this.dataForm, 'worthEstimated', fields.worthEstimated ? fields.worthEstimated : 0);
      this.$set(this.dataForm, 'incomeMonthly', fields.incomeMonthly ? fields.incomeMonthly : 0);
      this.$set(this.dataForm, 'profession', fields.profession);
      this.$set(this.dataForm, 'showMoreFields', fields.showMoreFields ? fields.showMoreFields : false);
    },
    quotation() {
      // limpando campos
      this.$store.commit('SET_FIELDS_LAWYER_MAKE_POLICY', {
        birthDate: null,
        phone: null,
        gender: null,
        exposedPerson: null,
        worthEstimated: null,
        incomeMonthly: null,
        profession: null,
        showMoreFields: null
      });

      //inputando campos
      this.$store.commit('SET_FIELDS_LAWYER_MAKE_POLICY', this.dataForm);
      this.redirect('step-5')
    }
  },
}
</script>

<style scoped>@import '../../../styles/lawyer.css';</style>
