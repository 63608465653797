import accounting from 'accounting';

export default {
  methods: {
    formatCurrency(number) {
      return accounting.formatMoney(number, 'R$ ', 2, '.', ',');
    },
    formatPercentage(number) {
      return accounting.formatMoney(number, { symbol: '%', format: '%v %s', precision: 2, decimal: ',', thousand: '.' });
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode === 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
}
