
export default {
  methods: {
    addZero(number) {
      return number <= 9 ? "0" + number : number
    },
    formatDateLocal(date) {
      var array = new Array();
      array = date.split(' ');
      array = array[0].split('-');
      return (array[2] + "/" + array[1] + "/" + array[0]);
    },
    formatDateISO(date) {
      var day = String(date.getDate()).padStart(2, '0');
      var month = String(date.getMonth() + 1).padStart(2, '0');
      var year = date.getFullYear();
      return [year, month, day].join("-");
    },
    formatDateFromISO(date) {
      const array = (date.substring(0, 10)).split('-');
      return (array[2] + "/" + array[1] + "/" + array[0]);
    },
    formatDateTimeFromISO(date) {
      date = new Date(date);      
      var day = String(date.getDate()).padStart(2, '0');
      var month = String(date.getMonth() + 1).padStart(2, '0');
      var year = date.getFullYear();
      
      return [day, month, year].join("/") + ' ' +
        [date.getHours(), date.getMinutes()].join(":");
    },
    calculateQuantityDays(start, stop) {
      var date1 = new Date(start);
      var date2 = new Date(stop);
      var differenceInTime = date2.getTime() - date1.getTime();
      return Math.floor(differenceInTime / (1000 * 3600 * 24));
    },
    addYears(date, years) {
      var array = date.split('-');
      var year = Number(array[0]) + Number(years);
      var month = array[1];
      var day = array[2];

      // todo: pog pra corrigir cagada do zoutro :/
      if (month === '02' && day === '29') {
          if (!((year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0))) {
              day = '28';
          }
      }

      return [year, month, day].join("-");
    },
    formatDateTimeLocal(val) {
      var dateComplete = new Array();
      var date = new Array();
      var hours = "";
      var dateFormatted = "";
      dateComplete = val.split(' ');
      date = dateComplete[0].split('-');
      hours = dateComplete[1];
      dateFormatted = date[2] + "/" + date[1] + "/" + date[0] + " " + hours
      return dateFormatted;
    },
    getLastCreatedDate(list) {
      if (list.length > 0) {
        return new Date(Math.max.apply(null, list.map(function (e) {
          return new Date(e.created_at);
        })));
      }
      return null;
    },
    strToDate(dateStr) {
      const parts = dateStr.split("-");
      const newDate = new Date(parts[0], (parts[1] - 1), parts[2]);
      return newDate;
    },
    getCurrentDate() {
      var todayDate = new Date();
      todayDate.setDate(todayDate.getDate());
      return todayDate.toISOString().split('T')[0];
    },
    addDays(date, days) {
      const copy = new Date(Number(date))
      copy.setDate(date.getDate() + days)
      return copy
    },
    formatDateTime(dateISO) {
      const date = new Date(dateISO);
      return date.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(',', '');
    },
    formatDateTimeCustom(dateISO) {
      const date = new Date(dateISO);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
  }
}
