import http from "@/services/http";

const quote = (params) => http.post('akad/quote', params)
const issuePolicy = (uid, params) => http.post(`akad/issue-policy/${uid}`, params)
const getDeductibles = (params) => http.post(`akad/get-deductibles`, params) 

export default {
  quote,
  issuePolicy,
  getDeductibles
}
