<template>
  <div class="container mx-auto text-center text-wrap-balance py-4" style="max-width: 600px;">
      <h2 class="mb-4">Sua emissão foi concluída com sucesso!</h2>

      <p class="text-muted mx-auto" style="max-width: 30ch;">
          Em breve, você recebera, no e-mail
          cadastrado, toda a documentação.
      </p>
      <p>
          <span class="text-muted">Abraços,</span><br>
          <strong>Equipe FINN Seguros</strong>
      </p>
  </div>
</template>

<script>
import uiHelperMixin from '@/mixins/uiHelper';

export default {
  name: "step-8",
  mixins: [uiHelperMixin],
  data: () => ({

  }),
  mounted() {
    this.scroll();
  },
  methods: {
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
  },
}
</script>

<style scoped>@import '../../../styles/lawyer.css';</style>
