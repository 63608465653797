<template>
  <div>
    <header class="text-center mb-5">

      <div class="row mb-4 align-items-center d-flex">
        <div class="col-12 col-md-2 col-lg-3 text-start">

          <a class="btn btn-link btn-sm font-16 text-muted">
            <strong v-if="currentIndex > 1 && currentIndex < 8" @click="redirect('step-'+ (currentIndex - 1).toString())">
              <i class="fa fa-angle-left me-1 d-inline-block d-md-none"></i>
              <span class="d-none d-md-inline-block">voltar</span>
            </strong>
            <strong v-else-if="currentIndex == 8">
              <span @click="resetFields(true)" class="d-md-inline-block">recomeçar</span>
            </strong>
          </a>

        </div>
        <div class="col-12 col-md-8 col-lg-6 px-5 mx-md-0">

          <ul class="wizard">
            <li v-for="(step, index) in steps" :key="step" class="d-block" :class="getItemClassObj(index)">
              <a class="d-block">
              </a>
            </li>
          </ul>

        </div>
        <div class="col-12 col-md-2 col-lg-3"></div>
      </div>

      <h2>{{ steps[currentIndex - 1] }}</h2>
      <p class="mb-0" v-if="currentIndex == 1">
        Seguro advogado pessoa
        <template v-if="personType == userTypes.NATURAL">física</template>
        <template v-else>jurídica</template>

      </p>

    </header>
  </div>
</template>

<script>
import userTypes from "@/enums/userTypes";

export default {
  name: "WizardNavigator",
  data: () => ({
    currentIndex: 0,
    disableStepsNavigation: false,
    userTypes: userTypes,
    personType: null
  }),
  props: {
    steps: {
      type: Array,
      required: true
    },
    routeIndexes: {
      type: Object,
      require: true
    }
  },
  created() {
    this.refreshRouteParams(this.$router.app._route);
    var personType = this.$store.getters.getLawyerFields.riskAnalysis.personType
    this.personType = personType ? personType : userTypes.NATURAL
    this.$root.$on('natural_person_type', () => {
      this.personType = userTypes.NATURAL
    });
    this.$root.$on('legal_person_type', () => {
      this.personType = userTypes.LEGAL
    });
  },
  methods: {
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
    getLinkStyle(index) {
      if (
        index >= this.currentIndex
        || this.disableStepsNavigation
        || !this.getRouteNameFromIndex(index)
      ) {
        return {};
      }

      return {
        'cursor': 'pointer'
      };
    },
    getIndexFromRouteName(routeName) {
      return this.routeIndexes[routeName];
    },
    getRouteNameFromIndex(index) {
      return Object.keys(this.routeIndexes).find(key => this.routeIndexes[key] === index);
    },
    resetFields(redirect = false) {
      this.$store.commit('RESET_LAWYER');
      this.personType = userTypes.NATURAL
      this.$root.$emit('reset-step-1', () => {});
      if(redirect){
        this.redirect('step-1')
      }
    },
    goToIndex(index) {
      if (index >= this.currentIndex || this.disableStepsNavigation) {
        return;
      }

      let params = {};
      if (this.identifier) {
        params = {
          [this.identifierRouteKey]: this.identifier
        };
      }
      const name_route = this.getRouteNameFromIndex(index);
      if (name_route) {
        this.$router.push({
          name: name_route,
          params
        });
      }
    },
    refreshRouteParams(routeObject) {
      const currentIndex = this.getIndexFromRouteName(routeObject.name);
      this.currentIndex = currentIndex ? currentIndex : 1;
      if (routeObject.meta.wizardNavigator) {
        this.disableStepsNavigation = routeObject.meta.wizardNavigator.disableStepsNavigation || false;
        return;
      }
      this.disableStepsNavigation = false;
    },
    getItemClassObj(itemIndex) {
      if (itemIndex < this.currentIndex && !this.disableStepsNavigation) {
        return [
          'checked',
          'active'
        ]
      }
      return [];
    }
  },
  watch: {
    '$route'(to) {
      this.refreshRouteParams(to);
    }
  }
};
</script>

<style scoped>

.text-start {
  text-align: left !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}


ul.wizard {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

ul.wizard li {
  isolation: isolate;
  flex: 1;
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.wizard li:last-child {
  flex: 0;
}

ul.wizard li::after {
  content: "";
  position: absolute;
  width: calc(100% - 0.2rem);
  height: 2px;
  background-color: #bbb;
  top: calc(50% - 1px);
  left: 0.2rem;
  z-index: -1;
}

ul.wizard li.active::after {
  background-color: #f165ff;
}

ul.wizard li.active a::before {
  background-color: #f165ff;
}

ul.wizard li a {
  position: relative;
  display: block;
  width: 1rem;
}

ul.wizard li a::before {
  content: "";
  display: block;
  border-radius: 1000px;
  background-color: #bbb;
  width: 1rem;
  height: 1rem;
}

ul.wizard li span {
  display: none;
}</style>
